import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import { Link } from 'react-router-dom';

const BugoMainLinkList: FC = () => {
  const { ChevronRight, Modify, Register } = Icon();

  return (
    <div className="flex flex-col gap-3">
      <div
        onClick={() => {
          alert(
            '2023.3.22 00시부로 데모버전에서 새로운 부고 등록을 하실 수 없습니다. 기존에 등록하셨던 부고 관리는 가능하십니다. 앞으로 aplus.gaonprime.com을 사용해주세요.',
          );
        }}
        className="px-3 py-2 pb-4 shadow-smd theme-bg-1"
      >
        <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
          <div className="pl-2 pr-4 ">
            <div className="center-box wh-10 rounded-full theme-bg-18">
              <Register />
            </div>
          </div>
          <p className="bugo-h1 w-full text-start">부고 등록</p>
          <div className="center-box wh-10">
            <ChevronRight />
          </div>
          <div></div>
          <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
            고인 및 상주 정보를 등록해주세요.
          </p>
        </div>
      </div>
      <Link to="/fevent/manage" className="px-3 py-2 pb-4 shadow-smd theme-bg-1">
        <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
          <div className="pl-2 pr-4">
            <div className="center-box wh-10 rounded-full theme-bg-18">
              <Modify />
            </div>
          </div>
          <p className="bugo-h1 w-full text-start">부고 관리</p>
          <div className="center-box wh-10">
            <ChevronRight />
          </div>
          <div></div>
          <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
            부고 수정 및 삭제를 할 수 있습니다.
          </p>
        </div>
      </Link>
    </div>
  );
};

export default BugoMainLinkList;
